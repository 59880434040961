import React from "react";
import TwitterIcon from "@mui/icons-material/Twitter";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import EmailIcon from "@mui/icons-material/Email";
import GithubIcon from "@mui/icons-material/GitHub";
import "../styles/Footer.css";

function Footer() {
  return (
    <div className="footer">
      <div className="socialMedia">
        <a href="https://twitter.com/Niall_Kehoe" target="blank">
        <TwitterIcon />
        </a>
        <a href="https://www.linkedin.com/in/niallkehoe/" target="blank">
        <LinkedInIcon />
        </a>
        <a href="mailto:nkehoe@stanford.edu" target="_blank" > 
          <EmailIcon />
        </a>
        <a href="https://github.com/niallkehoe" target="_blank" > 
          <GithubIcon />
        </a>
      </div>
      <p style={{color: "gray"}}> Niall Kehoe • &copy; 2023 </p>
    </div>
  );
}

export default Footer;