import { useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";

const ScrollToTop = ({ children }) => {
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        window.scrollTo({
          top: 0,
          left: 0,
          behavior: "smooth"
        });
    }, [location.pathname]);

    return children || null;
};

export default ScrollToTop;