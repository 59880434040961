// import FinPredict from "../assets/projects/finPredict.jpg";
// import Proj2 from "../assets/proj2.jpg";
// import Proj3 from "../assets/proj3.webp";
// import Proj4 from "../assets/proj4.webp";
// import Proj5 from "../assets/proj5.jpg";
import FinPredict from "../assets/projects/finPredict.jpg";

// import FinPredict0 from "../assets/projects/projectImages/finpredict/0.png";
// import FinPredict1 from "../assets/projects/projectImages/finpredict/1.png";
// import FinPredict2 from "../assets/projects/projectImages/finpredict/2.png";

export const ProjectList = [
  {
    name: "OnCourse.college",
    skills: "React JS, Tailwind CSS, Python, Firebase",
    shortDesc: "A class-scheduling web application for Stanford students. ",
    desc: `OnCourse.college is a class-scheduling web application built for Stanford students by me and my roommate.
    Our database was created by scraping publicly available sources and storing on Google Firebase. We 
    created many novel features such as 4 year planning, schedule sharing, and degree progress tracking.`,
    images : [],
    website: "https://oncourse.college",
  },
  {
    name: "FinPredict",
    image: FinPredict,
    skills: "Python, JS, HTML, CSS",
    shortDesc: "A stock market prediction tool that uses machine learning to predict the future price of index's based off past data.",
    desc: `My DATASCI112 final project was to create a stock market prediction tool that uses machine learning to predict the future price of index's based off past data. 
    I used a variety of different machine learning models to predict the future price of the S&P 500, NASDAQ, and Dow Jones Industrial Average. 
    I also created a website to display the predictions and allow users to input their own data to see the predictions. 
    `,
    github: "https://github.com/niallkehoe/FinPredict",
    images : ["0.png", "1.png", "2.png"]
  },
  {
    name: "Swift Student Challenge 2021",
    // image: FinPredict,
    skills: "Swift - ARKit, SceneKit, SwiftUI, UIKit, RealityKit, Reality Composer, SpriteKit and AVFoundation",
    shortDesc: "An interactive, educational app to many of the discoveries from some of the greatest human minds.",
    desc: `This year I decided to make an educational playground book which discussed topics from Mathematics, Technology and Computing. The project focuses on the work of Alan Turing, Albert Einstein, Leonhard Euler and more.

    Each chapter breaks down a concept/invention from the past and tries to give an intuition to how they work. Chapter One tackles Mathematical Constants (π, e, and the Golden Ratio), chapter two brings a WWII Enigma Machine to life and explains how the code was cracked, and chapter three demonstrates a thought experiment Einstein used when discovering special relativity.`,

    github: "https://github.com/niallkehoe/GreatMinds",
    youtube: "_m4rY34BQbM",
    images : ["0.jpg", "1.jpg", "2.jpg", "3.jpg"]
  },
  {
    name: "YouTube Video Analyzer",
    // image: Proj2,
    skills: "Python, JS, Node.js, Heroku",
    shortDesc: "A bot which scrapes gift card codes from “Try not to Laugh” YouTube videos.",
    desc: `Wrote a Node JS script to monitor the youtuber’s channel for uploads.
    If detected, a Python file on a Google Compute Engine virtual machine was launched. The VM downloaded the video and extracted the sound.
    The code scrubbed through the sound file and identified peaks in the sound intensity (the laughs). It then cropped the video to only the regions a few seconds before and after the peaks.
    The final video was converted to frames and uploaded to Google Vision API for text detection. The output was analysed and filtered to remove non-code text.
    The script opened Amazon and pasted in the codes to be redeemed.
    `
  },
  {
    name: "My Best Mate",
    // image: FinPredict,
    skills: "Python, JS, HTML, CSS, Swift, Java, XML",
    shortDesc: "A horse racing database that stores 40 years of horse races in Ireland (96,000 horses). ",
    desc:  `A horse racing database which stored 40 years of horse races in Ireland (96,000 horses).
    Wrote a Python script to scrape data from public sources and upload to Firebase using Javascript.
    Created 2 native apps for iOS and Android to connect to the backend.
    Developed a user-facing website to offer service to web users  and an admin portal to update the database.
    Implemented Stripe subscriptions using a Heroku server running Node JS.
    `,
  },
  {
    name: "Money Sports",
    // image: Proj3,
    skills: "R, Python",
    shortDesc: "A statistical analysis of the relationship between team payroll and success within various sports leagues.",
    desc: `A statistical analysis of the relationship between team payroll and success within various sports leagues.`,
    images : ["0.png"],
  },
  {
    name: "CS109 Challenge",
    // image: Proj4,
    skills: "Python",
    shortDesc: "A project exploring possible betting techniques using ELO ratings in order to predict winners.",
    desc: "A project exploring possible betting techniques using ELO ratings in order to predict winners.",
    images : ["0.jpg", "1.jpg", "2.jpg", "3.jpg"]
  },
];