import React, {useState} from "react";
import { useParams } from "react-router-dom";
import { ProjectList } from "../helpers/ProjectList";
import GitHubIcon from "@mui/icons-material/GitHub";
import YouTubeIcon from '@mui/icons-material/YouTube';
import DvrIcon from '@mui/icons-material/Dvr';
import "../styles/ProjectDisplay.css";

// import { Carousel } from '@flowbite/react';
// import '@flowbite/react/styles.css';
// import '@flowbite/react/styles.css';
// import { Carousel } from 'flowbite-react';
import { Carousel } from "@material-tailwind/react";
import YoutubeEmbed from "../components/YoutubeEmbed";

function ProjectDisplay() {
  const { id } = useParams();
  const project = ProjectList[id];

  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  const handleNext = () => {
    setCurrentImageIndex((prevIndex) => (prevIndex + 1) % project.images.length);
  };
  
  const handlePrev = () => {
    setCurrentImageIndex((prevIndex) => {
      if (prevIndex === 0) return project.images.length - 1;
      return prevIndex - 1;
    });
  };

  return (
    <div className="w-full h-2/3 flex flex-col justify-center items-center pt-10 mb-40">
      { project.image !== undefined ?
        <img src={project.image} className="w-24 h-24 rounded-lg mb-4"/>
      : null }
      <h1 className="font-semibold text-5xl text-blue-600 mb-2 bg"> {project.name}</h1>
      <p className="text-2xl pt-2">
        <b>Skills:</b> {project.skills}
      </p>

      <p className="text-xl w-1/2 mt-2 text-center text-gray-700">
        {project.desc}
      </p>

      {
        project.images !== undefined ? (
          <div className="imagePresentation justify-center w-2/5 mt-5">
            <Carousel className="rounded-xl">
              {project.images.map((image, idx) => {
                return (
                  <img
                    src={require(`../assets/projects/projectImages/${id}/${image}`)}
                    alt="image 3"
                    className="h-full w-full object-cover"
                  />
                );
              })
            }
          </Carousel>
          </div>
        ): null
      }

      {
        project.youtube !== undefined ? 
          (<div className="mt-10 w-1/3">
          <YoutubeEmbed embedId={project.youtube}/>
          </div>
          )
          : null
      }

      <div className="flex mt-10 space-x-4 text-blue-600">
        { project.github !== undefined ?
        <a href={project.github} target="_blank">
          <GitHubIcon style={{"font-size": "40px"}}/>
        </a>
        : null }
        { project.youtube !== undefined ?
        <a href={`https://youtu.be/${project.youtube}`} target="_blank">
          <YouTubeIcon style={{"font-size": "40px"}}/>
        </a>
        : null }
        { project.website !== undefined ?
        <a href={project.website} target="_blank">
          <DvrIcon style={{"font-size": "40px"}}/>
        </a> 
        : null }
      </div>
    </div>
  );
}

export default ProjectDisplay;

// .project {
//   width: 100%;
//   height: calc(100vh - 100px);
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   flex-direction: column;
// }

// .project h1 {
//   margin-top: 50px;
//   color: #3e497a;
//   font-size: 35px;
// }
// .project img {
//   width: 700px;
//   border-radius: 10px;
// }

// .project p {
//   font-size: 40px;
//   color: #3e497a;
// }

// .project svg {
//   font-size: 60px;
//   color: #3e497a;
// }