import React, { useEffect } from "react";
import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";

import SchoolIcon from "@mui/icons-material/School";
import WorkIcon from "@mui/icons-material/Work";

import stanfordHCI from "../assets/stanfordHCI.png";
import stanfordLogo from "../assets/stanford-logo.png";
import dyc from "../assets/dyc.jpeg";
import apple from "../assets/apple.png";
import youtubeLogo from "../assets/youtube.png";
import githubLogo from "../assets/github.png";
// import WorkHistoryIcon from '@material-ui/icons/WorkHistory';
// import WorkIcon from "@material-ui/icons/WorkHistory";
import WorkHistoryIcon from '@mui/icons-material/WorkHistory';
import WMTIcon from '../assets/wmt.png';

import "../styles/Experience.css";

function Experience() {
  useEffect(() => {
    document.title = 'Niall Kehoe | Experience';
  }, []);

  return (
    <div className="experience">
      <VerticalTimeline lineColor="#3e497a">
      <VerticalTimelineElement
          className="vertical-timeline-element--work"
          date="Jun 2023 - Sep 2023"
          iconStyle={{ background: "#e9d35b", color: "#fff"}}
          icon={ <img class="timelineImg" src={stanfordHCI} alt="Stanford HCI" /> }
        >
          <h3 className="vertical-timeline-element-title">
            Research Assistant | Stanford HCI | CURIS
          </h3>
          <h4 className="vertical-timeline-element-subtitle">
            <i>Swift, Python, SwiftUI, React, Generative AI</i>
          </h4>
          <p>
            Developing a system to generate new knowledge regarding aspects of the built 
            environment that promote both environmental sustainability and occupant well-being.
          </p>
        </VerticalTimelineElement>

        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          date="Mar 2023 - Present"
          iconStyle={{ background: "white", color: "#fff" }}
          icon={ <img class="timelineImg" src={stanfordLogo} alt="CS198 Section Leader" /> }
        >
          <h3 className="vertical-timeline-element-title">
            CS198 Section Leader | <a href="https://cs198.stanford.edu">Stanford University</a>
          </h3>
          <h4 className="vertical-timeline-element-subtitle">
            <i>C++, Python </i>
          </h4>
          <p>Teaching Assisgance for CS 106A and 106B, Stanford's intro programming/data structures sequence in Python & C++.</p>
        </VerticalTimelineElement>

        <VerticalTimelineElement
          className="vertical-timeline-element--education"
          date="Sep 2022 - June 2026"
          iconStyle={{ background: "#3e497a", color: "#fff" }}
          icon={<SchoolIcon />}
        >
          <h3 className="vertical-timeline-element-title">
            Stanford University
          </h3>
          <h4 className="vertical-timeline-element-subtitle" style={{ color: '#32a868' }}>
            Stanford, CA.
          </h4>

          <p>
          <b>BSc</b> in <b>Computer Science</b> and a Minor in <b>Statistics</b>.
          <br></br>
          <b>MSc</b> in <b>Computer Science</b> (Coterminal Program).
          <br></br>
          <b>GPA</b>: 3.97
          </p>
        </VerticalTimelineElement>

        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          date="Jun 2021"
          iconStyle={{ background: "white" }}
          icon={ <img class="timelineImg" src={apple} alt="Apple" /> }
        >
          <h3 className="vertical-timeline-element-title">
            Swift Student Challenge 2021 | Apple
          </h3>
          <h4 className="vertical-timeline-element-subtitle">
            <i>Swift, Reality Kit, SceneKit, SwiftUI, ARKit</i>
          </h4>

          <p> I was one of 350 developers selected to attend the conference as a scholarship winner, receiving a virtual WWDC ticket, 
            and one year of membership in the Apple Developer Program free of charge.
          </p>


          <div className="image-container">
            <div style={{ width: '25%', marginRight: '10px'}}>
              <a href="https://youtu.be/_m4rY34BQbM" target="_blank" rel="noopener noreferrer" >
                <img src={youtubeLogo} alt="YouTube" className="youtube-button" 
                style={{ width: '100%', height: 'auto', paddingTop: '10px' }} />
              </a>
            </div>

            <div style={{ width: '25%'}}>
              <a href="https://github.com/niallkehoe/GreatMinds" target="_blank" rel="noopener noreferrer" >
                <img src={githubLogo} alt="GitHub" className="github-button" 
                style={{ width: '100%', height: 'auto', paddingTop: '10px' }} />
              </a>
            </div>
          </div>
        </VerticalTimelineElement>

        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          date="Jun 2020"
          iconStyle={{ background: "white" }}
          icon={ <img class="timelineImg" src={apple} alt="Apple" /> }
        >
          <h3 className="vertical-timeline-element-title">
            Swift Student Challenge 2020 | Apple
          </h3>
          <h4 className="vertical-timeline-element-subtitle">
            <i>Swift, ARKit, CoreML</i>
          </h4>

          <p> I was one of 350 developers selected to attend the conference as a scholarship winner, receiving a virtual WWDC ticket, 
            and one year of membership in the Apple Developer Program free of charge.
          </p>

          <a href="https://youtu.be/nbuuas18zgA" target="_blank" rel="noopener noreferrer" >
            <img src={youtubeLogo} alt="YouTube" className="youtube-button" style={{ width: '25%', paddingTop: '10px' }}/>
          </a>
        </VerticalTimelineElement>

        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          date="Sep 2019 - Mar 2020"
          iconStyle={{ background: "#25b035", color: "#fff" }}
          icon={<WorkHistoryIcon />}
        >
          <h3 className="vertical-timeline-element-title">
          Software Engineering Intern | Varoom Ltd & Innovate Ltd
          </h3>
          <h4 className="vertical-timeline-element-subtitle">
            <i>Swift, Objective-C, Python, JS, HTML, CSS</i>
          </h4>

          <p> 
            <b>Varoom Ltd</b>: I developed iOS, Android, and web applications for clients.
            <br></br>
            <b>Innovate Ltd</b>: I assisted in the maintenance of clients' cloud systems.
          </p>
        </VerticalTimelineElement>

        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          date="Jun 2018"
          iconStyle={{ background: "white" }}
          icon={ <img class="timelineImg" src={apple} alt="Apple" /> }
        >
          <h3 className="vertical-timeline-element-title">
            WWDC 2018 Scholarship | Apple
          </h3>
          <h4 className="vertical-timeline-element-subtitle">
            <i>Swift, Objective C</i>
          </h4>

          <p> I was one of 350 developers selected to attend the conference as a scholarship winner, receiving a WWDC ticket, 
          flights and lodging for the conference, and one year of membership in the Apple Developer Program free of charge.
          </p>
        </VerticalTimelineElement>

        <VerticalTimelineElement
          className="vertical-timeline-element--education"
          date="Jun 2017"
          iconStyle={{ background: "white" }}
          icon={ <img class="timelineImg" src={apple} alt="Apple" /> }
        >
          <h3 className="vertical-timeline-element-title">
            WWDC 2017 Scholarship | Apple
          </h3>
          <h4 className="vertical-timeline-element-subtitle">
            <i>Swift, Objective C</i>
          </h4>

          <p> I was one of 350 developers selected to attend the conference as a scholarship winner, receiving a WWDC ticket, 
          flights and lodging for the conference, and one year of membership in the Apple Developer Program free of charge.
          </p>

          <a href="https://youtu.be/qk9I2y4WvZw" target="_blank" rel="noopener noreferrer" >
            <img src={youtubeLogo} alt="YouTube" className="youtube-button" style={{ width: '25%', paddingTop: '10px' }}/>
          </a>
        </VerticalTimelineElement>

        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          date="2016 - 2022"
          iconStyle={{ background: "#3e497a", color: "#fff" }}
          icon={ <img class="timelineImg" src={dyc} alt="DYC" /> }
        >
          <h3 className="vertical-timeline-element-title">
            Board Member | Digital Youth Council of Ireland
          </h3>

          <p> 
            The Digital Youth Council aims  to give a voice to young people involved in technology
            in Ireland and provide them with a platform to influence the National Digital Strategy,
            and the future of technology in education
          </p>
        </VerticalTimelineElement>
        
        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          date="Apr 2014 - Present"
          iconStyle={{ background: "white", color: "#fff", padding: '4px' }}
          icon={ <img class="timelineImg" src={WMTIcon} alt="DYC" /> }
        >
          <h3 className="vertical-timeline-element-title">
            CEO | Woolly Mammoth Tech
          </h3>

          <p> 
            I developed iOS, Android, and web applications for clients as well as my own projects .
          </p>
        </VerticalTimelineElement>

        
      </VerticalTimeline>
    </div>
  );
}

export default Experience;