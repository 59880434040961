import React, { useEffect, useState } from "react";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import EmailIcon from "@mui/icons-material/Email";
import GithubIcon from "@mui/icons-material/GitHub";
import "../styles/Home.css";

// import headshot1 from "../assets/headshot.jpg"
import headshot2 from "../assets/headshot2.jpg"
import stanfordLogo from "../assets/stanford-logo.png";
import { useIsMobile } from "../helpers/Mobile";

function Home() {
  useEffect(() => {
    document.title = 'Niall Kehoe';
  }, []);

  var [isMobile, setIsMobile] = useState(useIsMobile());
  const mobileClass = isMobile ? 'mobile' : 'desktop'
  var [mobileCourseOpen, setMobileCourseOpen] = useState(false);


  return (
    <div className='home'>
        <div className='about'>
            <img src={headshot2} alt="Niall Kehoe" id="headshotImg"/>
            <h2 className="text-6xl mt-10">Hi, I'm Niall Kehoe.</h2>
            <div className='w-3/5'>
                <h4 className={`text-3xl pt-${isMobile ? 0 : 10}`}>
                    <span className="font-extrabold text-[#f1da2b]">Computer Science</span> and <span className="font-extrabold text-blue-500">Statistics</span> at 
                    {/* <img src={stanfordLogo} alt="Stanford University" id="stanfordImg"/> */}
                    <span className="font-extrabold text-red-500"> Stanford University</span>.
                </h4>

              <div className={`flex items-center justify-center space-x-5 pt-${isMobile ? 0 : 10}`}>
                <a href="https://www.linkedin.com/in/niallkehoe/" target="_blank" rel="noopener noreferrer">
                      <LinkedInIcon className="cursor-pointer" style={{"font-size": "60px"}}/>
                </a>
                <a href="mailto:nkehoe@stanford.edu" target="_blank" > 
                  <EmailIcon className="cursor-pointer" style={{"font-size": "60px"}}/>
                </a>
                <a href="https://github.com/niallkehoe" target="_blank" > 
                  <GithubIcon className="cursor-pointer" style={{"font-size": "60px"}}/>
                </a>
              </div>
            </div>
        </div>
        <div className="skills">
        <h1 style={{marginTop: "25px", marginBottom: "0px"}}> Skills</h1>
        <ol className="list">
          <li className="item">
            <h2>Languages</h2>
            <span>C++, Swift, Python, C, Java, Objective C, HTML, CSS, JavaScript, C#, Php, R, XML.</span>
          </li>
          <li className="item">
            <h2>Interests</h2>
            <span>Artificial Intelligence, Machine Learning, Finance, Sports.</span>
          </li>
        </ol>
      </div>


    </div>
  )
}

export default Home