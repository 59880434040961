import React, { useEffect } from "react";
import ProjectItem from "../components/ProjectItem";
import { ProjectList } from "../helpers/ProjectList";

import "../styles/Projects.css";
import AppStore from "../assets/appstore.png";

function Projects() {
  useEffect(() => {
    document.title = 'Niall Kehoe | Projects';
  }, []);

  return (
    <div className="w-full flex flex-col justify-center items-center">
    <span className="pt-5 mb-4 text-xl font-extrabold tracking-tight leading-none text-gray-900 md:text-5xl lg:text-4xl text-blue-600 text-transparent bg-clip-text bg-gradient-to-r from-[#4933f2] to-[#3366f2]">Projects</span>

    <div className="w-9/12">
        <div className="grid grid-cols-3 gap-3 px-10">
            {ProjectList.map((project, idx) => {
                return (
                    <ProjectItem id={idx} name={project.name} image={project.image} desc={project.shortDesc} />
                );
            })}
        </div>
    </div>


      {/* <div className="viewAppsWrapper">
        <div className="viewApps">
          <img src={AppStore} className="appStoreImg" alt="App Store"/>
          <h3 className="viewLbl">View my Apps on the App Store</h3>
        </div>
      </div> */}

        <button class="relative inline-flex items-center justify-center mt-10 p-0.5 mb-2 mr-2 overflow-hidden text-sm font-medium text-gray-900 rounded-lg group transform hover:scale-105 transition-transform duration-300"
                onClick={() => { 
                    // open in new tab
                    window.open("https://apps.apple.com/lv/developer/niall-kehoe/id845323924", "_blank");

                 }}>
            <span class="flex items-center text-lg relative px-5 py-2.5 space-x-1 border-4 border-gray-900 rounded-lg">
                <img src={AppStore} alt="App Store" className="w-16 h-16"/>
                <span className="text-xl pl-3 font-bold">
                    View my apps on the App Store
                </span>
            </span>
        </button>
    </div>
  );
}

export default Projects;