import React from "react";
import { useNavigate } from "react-router-dom";

function ProjectItem(props) {
  const { name, image, id, desc } = props;

  const navigate = useNavigate();

  return (

    <div
    className="projectItem relative p-[4px] cursor-pointer hover:text-white dark:text-white focus:outline-none focus:ring-4 focus:ring-green-200 dark:focus:ring-green-800
    transform hover:scale-105 transition-transform duration-300"
    onClick={() => {
      navigate("/project/" + id);
    }}
  >
    {/* Gradient "border" */}
    <div className="absolute inset-0 rounded-lg z-0 bg-gradient-to-r from-[#1499f7] via-[#8A2BE2] to-[#FF69B4]"></div>
    
    {/* Content */}
    <div className="relative p-5 bg-white rounded-lg z-10 h-full">
      <div className="text-black">
        <h1 className="projectTitles">{name}</h1>
        <p>{desc}</p>
      </div>
    </div>
  </div>
  );
}

export default ProjectItem;