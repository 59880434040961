import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import ReorderIcon from "@mui/icons-material/Reorder";
import headshot from "../assets/headshot2.jpg";

import "../styles/Navbar.css";

function Navbar() {
    const [expandNavbar, setExpandNavbar] = useState(false)
    const location = useLocation()

    useEffect(() => {
        setExpandNavbar(false)
    }, [location])


  return (
    // <div className='navbar' id={expandNavbar ? "open" : "close"}> 
    <div className='navbar' id={expandNavbar ? "open" : "close"}>
        <div className='toggleButton'>
            <button 
                onClick={() => {
                    setExpandNavbar((prev) => !prev)
                }}
            > 
                <ReorderIcon / > 
                    {/* Button for menu if the screen width is too small */}
            </button>
        </div>

        <div className='parentContainer'>
            <div className='links'>
                <div className="leftOptions">
                    <img src={headshot} id="headshotNav"/>
                    <a href="/">
                        <span className="nameLeft">
                        Niall Kehoe
                        </span>
                    </a>
                </div>
                <div className='rightOptions'>
                    <Link to='/'>Home</Link>
                    <Link to='/projects'>Projects</Link>
                    <Link to='/experience'>Experience</Link>
                    {/* <Link to='/academics'>Academics</Link> */}
                    
                    <a href="/resume/NIALL_KEHOE_RESUME.pdf" target="blank">Resume</a>
                </div>
            </div>
        </div>
    </div>
    
  );
}


export default Navbar