import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import Projects from "./pages/Projects";
import Experience from "./pages/Experience";
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import ScrollToTop from "./components/ScrollToTop";
import ProjectDisplay from "./pages/ProjectDisplay";
// import Academics from "./pages/Academics";

function App() {
  return (
    <div className="App">
      <Router>
        <Navbar />

        <ScrollToTop>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/projects" element={<Projects />} />
            <Route path="/project/:id" element={<ProjectDisplay />} /> 
            <Route path="/experience" element={<Experience />} />
            {/* <Route path="/academics" element={<Academics />} /> */}

          </Routes>
        </ScrollToTop>
        <Footer />
      </Router>
    </div>
  );
}

export default App;
